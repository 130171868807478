import { WidgetViewModel } from '../../viewModel/viewModel';
import { EnrichedService } from '../../types/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { ScheduleServer } from '@wix/ambassador-schedule-server/http';
import {
  ListSchedulesRequest,
  Schedule,
  ScheduleStatus,
} from '@wix/ambassador-schedule-server/types';
import { CoursesAvailability } from './CoursesAvailability';
import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';

export type CourseAvailabilityAction = () => void;

interface Params {
  widgetViewModel: WidgetViewModel;
  services: EnrichedService[];
  setProps: Function;
  flowAPI: ControllerFlowAPI;
}

const SCHEDULES_REQUEST = 100;

function cacheCourseAvailability(
  flowAPI: ControllerFlowAPI,
  coursesAvailability: CoursesAvailability,
) {
  flowAPI.controllerConfig.platformAPIs.storage.memory.setItem(
    `bookings.courseAvailability-${flowAPI.controllerConfig.compId}`,
    JSON.stringify(coursesAvailability),
  );
}

export function getCourseAvailabilityFromCache(flowAPI: ControllerFlowAPI) {
  try {
    const courseAvailability =
      flowAPI.controllerConfig.platformAPIs.storage.memory.getItem(
        `bookings.courseAvailability-${flowAPI.controllerConfig.compId}`,
      );
    return JSON.parse(courseAvailability || 'null');
  } catch (e) {
    return null;
  }
}

async function fetchCourseAvailability(
  services: EnrichedService[],
  instance: string,
) {
  const schedulesService = ScheduleServer(
    '/_api/schedule-reader-server/',
  ).Schedules();
  const scheduleOwnerIds = services
    .filter(({ type }) => type === ServiceType.COURSE)
    .map(({ id }) => id!);
  let schedules: Schedule[] = [];

  for (let i = 0; i < scheduleOwnerIds.length; i += SCHEDULES_REQUEST) {
    const partialScheduleOwnerIds = scheduleOwnerIds.slice(
      i,
      i + SCHEDULES_REQUEST,
    );
    const listSchedulesRequest: ListSchedulesRequest = {
      scheduleOwnerIds: partialScheduleOwnerIds,
    };
    const { schedules: partialSchedules } = await schedulesService({
      authorization: instance,
    }).list(listSchedulesRequest);
    schedules = [...schedules, ...(partialSchedules || [])];
  }

  const coursesAvailability: CoursesAvailability = schedules.reduce(
    (acc, s) => {
      if (s.status === ScheduleStatus.CREATED) {
        acc[s?.scheduleOwnerId!] = {
          spotsLeft: s?.capacity! - s?.totalNumberOfParticipants!,
        };
      }
      return acc;
    },
    {} as CoursesAvailability,
  );
  return coursesAvailability;
}

export async function getCourseAvailability(
  flowAPI: ControllerFlowAPI,
  services: EnrichedService[],
): Promise<CoursesAvailability | undefined> {
  const {
    controllerConfig: {
      appParams: { instance },
    },
  } = flowAPI;

  try {
    let coursesAvailability: CoursesAvailability =
      getCourseAvailabilityFromCache(flowAPI);
    if (!coursesAvailability) {
      coursesAvailability = await fetchCourseAvailability(services, instance);
      cacheCourseAvailability(flowAPI, coursesAvailability);
    }
    return coursesAvailability;
  } catch (e) {
    // TODO: report an error?
  }
}

export const createCourseAvailabilityAction = ({
  widgetViewModel,
  services,
  setProps,
  flowAPI,
}: Params) => {
  return async () => {
    const coursesAvailability = await getCourseAvailability(flowAPI, services);
    if (coursesAvailability) {
      setProps({
        widgetViewModel: { ...widgetViewModel, coursesAvailability },
      });
    }
  };
};
