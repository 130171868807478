import { ServiceType } from '@wix/ambassador-bookings-services-v2-service/types';
import { GetBusinessResponse } from '@wix/ambassador-services-catalog-server/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { EnrichedService } from '../../../types/types';
import {
  isFeatureEnabled,
  isServiceHasPricingPlanError,
} from '@wix/bookings-calendar-catalog-viewer-mapper/domain';
import {
  isDemoMode,
  isTemplateMode,
} from '@wix/bookings-catalog-calendar-viewer-utils';

export enum BlockNavigationReason {
  NOT_PREMIUM = 'NOT_PREMIUM',
  PRICING_PLAN_ERROR = 'PRICING_PLAN_ERROR',
}

export function getBlockNavigationReason({
  flowAPI,
  service,
  isPricingPlanInstalled,
  getBusinessResponse,
}: {
  flowAPI: ControllerFlowAPI;
  service: EnrichedService;
  isPricingPlanInstalled: boolean;
  getBusinessResponse: GetBusinessResponse;
}): BlockNavigationReason | undefined {
  const { activeFeatures } = getBusinessResponse;
  const {
    environment: { isPreview },
    controllerConfig: { appParams, wixCodeApi },
  } = flowAPI;

  if (
    isPreview ||
    isDemoMode({ appParams, wixCodeApi }) ||
    isTemplateMode({ appParams, wixCodeApi })
  ) {
    return undefined;
  }

  if (
    service.type !== ServiceType.COURSE &&
    !isFeatureEnabled({ activeFeatures: activeFeatures!, service })
  ) {
    return BlockNavigationReason.NOT_PREMIUM;
  }

  if (
    isServiceHasPricingPlanError({
      isPricingPlanInstalled,
      payment: service.payment!,
    })
  ) {
    return BlockNavigationReason.PRICING_PLAN_ERROR;
  }
}
