import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { DialogType } from '../../viewModelDeprecated/dialogViewModel/dialogViewModel';
import { WidgetViewModel } from '../../viewModelDeprecated/viewModel';

export type OpenPremiumDialogAction = () => void;

export const createOpenPremiumDialogAction = ({
  widgetViewModel,
  setProps,
  flowAPI,
}: {
  widgetViewModel: WidgetViewModel;
  setProps: Function;
  flowAPI: ControllerFlowAPI;
}) => {
  return () => {
    widgetViewModel.dialogViewModel.isOpen = true;
    widgetViewModel.dialogViewModel.type = DialogType.Premium;
    if (
      !flowAPI.experiments.enabled('specs.bookings.preCalculateShouldNavigate')
    ) {
      widgetViewModel.navigatingToService = '';
    }

    setProps({
      widgetViewModel: {
        ...widgetViewModel,
      },
    });
  };
};
