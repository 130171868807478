import { ActiveFeatures, CatalogServiceDto } from '@wix/bookings-uou-types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import {
  createServiceImageViewModel,
  ServiceImageViewModel,
} from './serviceImageViewModel/serviceImageViewModel';
import {
  BodyViewModel,
  createBodyViewModel,
} from './bodyViewModel/bodyViewModel';
import { createFilterOptions } from './filterOptions/filterOptions';
import { createHeaderViewModel } from './headerViewModel/headerViewModel';
import { getServices } from './services/services';
import {
  FilterOption,
  Resources,
  ServiceDeprecated,
  ServiceListLayoutOptions,
  ViewMode,
} from '../types/types';
import { BusinessInfo as LegacyBusinessInfo } from '../../legacy/types';
import { CoursesAvailability } from '../actionsDeprecated/courseAvailability/CoursesAvailability';
import settingsParams from '../components/BookOnline/settingsParams';
import {
  createDialogViewModel,
  DialogViewModel,
} from './dialogViewModel/dialogViewModel';
import {
  isLiveSite,
  shouldBlockNavigation,
} from '../utils/shouldBlockNavigationDeprecated/shouldBlockNavigation';
import { isPricingPlanInstalled as isPricingPlanInstalledUtils } from '@wix/bookings-catalog-calendar-viewer-utils';
import { shouldOpenServicesPreferencesModal } from '../utils/shouldOpenServicesPreferencesModal/shouldOpenServicesPreferencesModal';

export type WidgetViewModel = {
  services: ServiceDeprecated[];
  businessInfo: LegacyBusinessInfo;
  filterOptions: FilterOption[];
  serviceImageViewModel: ServiceImageViewModel;
  headerViewModel?: any;
  bodyViewModel: BodyViewModel;
  seo?: {
    shouldListServicesFromOtherCategories: boolean;
    allServices: CatalogServiceDto[];
  };
  isSEO: boolean;
  serviceListLayout: ServiceListLayoutOptions;
  coursesAvailability?: CoursesAvailability;
  navigatingToService?: string;
  shouldWorkWithAppSettings: boolean;
  dialogViewModel: DialogViewModel;
};

export const createWidgetViewModel = async ({
  filteredResources,
  businessInfo,
  flowAPI,
  scale,
  viewMode,
  isAnywhereFlow = false,
  shouldWorkWithAppSettings,
  activeFeatures,
}: {
  filteredResources: Resources;
  businessInfo: LegacyBusinessInfo;
  flowAPI: ControllerFlowAPI;
  scale: number;
  viewMode: ViewMode;
  isAnywhereFlow?: boolean;
  shouldWorkWithAppSettings: boolean;
  activeFeatures: ActiveFeatures;
}): Promise<WidgetViewModel> => {
  const {
    settings,
    environment: { isMobile },
  } = flowAPI;
  const allServices = filteredResources.offerings;

  const filterOptions = createFilterOptions({
    flowAPI,
    filteredResources,
  });

  const [services, isPricingPlanInstalled] = await Promise.all([
    getServices({
      filterOptions,
      filteredResources,
      settings,
      isAnywhereFlow,
    }) as Promise<ServiceDeprecated[]>,
    isPricingPlanInstalledUtils(flowAPI.controllerConfig.wixCodeApi),
  ]);

  const serviceListLayout = settings.get(settingsParams.serviceListLayout);

  const serviceImageViewModel = createServiceImageViewModel({
    settings,
    isMobile,
    serviceListLayout,
  });

  const bodyViewModel = createBodyViewModel({
    flowAPI,
    scale,
    imageAspectRatio: serviceImageViewModel.aspectRatio,
    serviceListLayout,
    services,
    isPricingPlanInstalled,
  });

  const headerViewModel = createHeaderViewModel(settings, filterOptions);

  const shouldListServicesFromOtherCategories =
    viewMode === ViewMode.PAGE && services.length !== allServices.length;
  const seo = {
    shouldListServicesFromOtherCategories,
    allServices,
  };

  const dialogViewModel = createDialogViewModel();
  if (
    flowAPI.experiments.enabled('specs.bookings.preCalculateShouldNavigate')
  ) {
    services.forEach((service) => {
      if (isLiveSite(flowAPI)) {
        service.blockNavigationReason = shouldBlockNavigation({
          businessInfo,
          service,
          flowAPI,
          activeFeatures,
          isPricingPlanInstalled,
        });
      }
      if (
        flowAPI.experiments.enabled(
          'specs.bookings.msaNotPartOfBlockNavigation',
        )
      ) {
        service.multiServiceAppointmentActivated =
          shouldOpenServicesPreferencesModal({
            service,
            flowAPI,
            isMultiServicesAppointmentsEnable:
              // @ts-expect-error
              !!businessInfo.isMultiServicesAppointmentsEnable,
          });
      }
    });
  }

  return {
    services,
    filterOptions,
    serviceListLayout,
    serviceImageViewModel,
    headerViewModel,
    bodyViewModel,
    businessInfo,
    seo,
    isSEO: flowAPI.environment.isSEO,
    shouldWorkWithAppSettings,
    dialogViewModel,
  };
};
