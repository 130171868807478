import { CatalogServiceDto } from '@wix/bookings-uou-types';
import { WidgetViewModel } from '../../viewModelDeprecated/viewModel';

export const setServiceLoader = ({
  setProps,
  widgetViewModel,
  service,
}: {
  setProps: Function;
  widgetViewModel: WidgetViewModel;
  service?: CatalogServiceDto;
}) => {
  widgetViewModel.navigatingToService = service?.id || '';
  setProps({
    widgetViewModel: {
      ...widgetViewModel,
    },
  });
};
